<!--
 * @Author: Jerry
 * @Date: 2021-02-25 12:18:15
 * @LastEditors: Jerry
 * @LastEditTime: 2021-03-10 15:08:27
 * @Description: file content
-->
<template>
  <div class="item">
    <router-link tag="a" :to="{ name: 'GoodDetail', query: { id: info.goodsId } }" target="_blank">
      <el-image
        :src="info.goodsLogo"
        style="width: 218px; height: 218px"
        fit="cover"
      ></el-image>
      <div class="price co_f00 FBold">
        <span class="F20">￥</span>
        <span v-if="info.goodsPrice==0" class="F22">面议</span>
        <span v-else class="F22">{{ info.goodsPrice }}</span>
      </div>
      <div class="title">
        <a class="F12" v-html="info.goodsTitle"></a>
      </div>
    </router-link>
  </div>
</template>
<script>
export default {
  name: "goodsItem",
  data: () => ({
    image:
      "https://g-search1.alicdn.com/img/bao/uploaded/i4/imgextra/i4/439150132/O1CN01aeKtNN1CqUuDYPP7K_!!0-saturn_solar.jpg_230x230.jpg_.webp",
  }),
  props: {
    info: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    // 商品详情页
    goDetail(id) {
      this.$router.push({
        name: "GoodDetail",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";
.item {
  width: 218px;
  height: 320px;
  border: 1px solid #d9d9d9;
  margin-right: 25px;
  margin-top: 20px;
  float: left;
  cursor: pointer;
  &:hover {
    border: 1px solid $primaryColor;
    box-shadow: 0px 0px 7px -1px $primaryColor;
  }
  .price {
    padding: 10px 8px 0;
  }
  .title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    padding: 1px 12px;
    height: 45px;
    overflow: hidden;
    text-overflow: ellipsis;
    a:hover {
      color: $primaryColor;
      text-decoration: underline;
    }
  }
}
</style>