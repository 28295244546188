<template>
  <div>
    <div class="grid">
      <!-- 商品tiems -->
      <goodsItem v-for="item in dataSource" :key="item.id" :info="item"></goodsItem>
      <div class="notData" v-if="dataSource.length == 0">
        <span class="F14 co_999">暂无商品数据</span>
      </div>
    </div>
  </div>
</template>
<script>
import goodsItem from "./goodsItem";
export default {
  name: "goodsList",
  components: { goodsItem },
  data() {
    return {};
  },
  props: {
    dataSource: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.grid {
  overflow: hidden;
}
.notData {
  width: 1198px;
  height: 500px;
  border: 1px solid #e8e8e8;
  margin-top: 20px;
  line-height: 500px;
  text-align: center;
  color: #e8e8e8;
  letter-spacing: 2px;
}
</style>